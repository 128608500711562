<template>
  <div class="settings-lead">
    <div style="width: 100%;">
      <HbPropertySelector v-if="isPropertyLevel" id="facility" :items="facilityList" item-text="name" item-value="id"
        v-validate="'required'" v-model="property_id" data-vv-name="facility" data-vv-as="Property"
        :error="errors.has('facility')" />
      <!-- <hb-notification v-if="!isPropertyLevel && selection == 0" type="guidance" :not-dismissable="true">
        Default questions CANNOT be deleted or rearranged.
      </hb-notification> -->
      <!-- <div v-if="!isPropertyLevel && selection == 0" class="sub-header-align">
        <div>
          <div class="hb-font-header-3-medium ml-1 ">
            Lead Questionnaire
          </div>
          <div class="hb-font-caption ml-1">
            Provide and arrange questions and answers for employees to ask leads during follow process.
          </div>
        </div>

      </div> -->
      <div class="mr-6" :class="{ 'pt-1': !isPropertyLevel }">
        <v-expansion-panels elevation="0" class="hb-expansion-panel" v-model="setupModal">
          <hb-expansion-panel v-if="!isPropertyLevel">
            <template v-slot:title> Lead Questionnaire </template>
            <template v-slot:content>
              <div class="questionnaire-container">
                <hb-notification v-if="!isPropertyLevel" type="guidance" :not-dismissable="true">
                  Default questions CANNOT be deleted or rearranged.
                </hb-notification>
                <div class="sub-header-align">
                  <div class="hb-font-caption ml-1">
                    Provide and arrange questions and answers for employees to ask leads during follow process.
                  </div>
                  <div class="mr-4" v-if="!isPropertyLevel">
                    <hb-btn color="secondary" :disabled="disableAddQuestion" prepend-icon="mdi-plus"
                      @click="addQuestion">Add
                      Question</hb-btn>
                  </div>

                </div>
                <div class="draggable-dropdown">
                  <!-- <draggable v-model="questionnaires"> -->
                  <LeadQuestionary v-for="(questionnaire, index) in questionnaires" :key="index"
                    :isPropertyLevel="!isPropertyLevel" :question="questionnaire" :isDefault="questionnaire.isDefault">
                  </LeadQuestionary>
                  <!-- </draggable> -->
                </div>
                <div v-if="!isPropertyLevel">
                  <v-expansion-panels elevation="0" class="hb-expansion-panel" v-model="customQuestionnaireModel">
                    <draggable :list="customQuestionnaires" @update="saveNewOrder" :disabled="disableAddQuestion" class="draggable-dropdown-2" >
                      <LeadQuestionary v-for="(customQuestionnaire, index) in customQuestionnaires" :key="index"
                        :isPropertyLevel="!isPropertyLevel" :question="customQuestionnaire.question"
                        :sortOrder="customQuestionnaire.sort_order" :isDefault="false"
                        :questionId="customQuestionnaire.id" @updateQuestions="setNewOrderedQuestion">
                      </LeadQuestionary>
                    </draggable>
                  </v-expansion-panels>
                </div>
              </div>
            </template>
          </hb-expansion-panel>
          <hb-expansion-panel :top-margin="true" key="lead_scripts" v-if="isPropertyLevel ? !disableBtn :true">
            <template v-slot:title> The Script </template>
            <template v-slot:content>
              <lead-scripts :property_id="property_id" :disableBtn="false"></lead-scripts>
            </template>
          </hb-expansion-panel>
          <ReservationSettings v-if="!isPropertyLevel"></ReservationSettings>
          <apw-setting :key="property_id" v-if="isPropertyLevel ? !disableBtn : true"
            :property_id="property_id" :isPropertyLevel="isPropertyLevel"></apw-setting>
          
        </v-expansion-panels>
        <hb-empty-state v-if="disableBtn && isPropertyLevel" message="Please select a Property to continue.">
        </hb-empty-state>
      </div>
    </div>
    <!-- <div v-if="!isPropertyLevel" style="height: 75vh;">
      <v-navigation-drawer
                v-model="showAside"
                :right="!$vuetify.breakpoint.xs"
                :hide-overlay="$vuetify.breakpoint.smAndUp"
                :clipped="!$vuetify.breakpoint.xs"
                :class="{'attached-drawer': !$vuetify.breakpoint.xs}"
                disable-resize-watcher
                mobile-breakpoint="600"
                class="ma-0 pa-0 py-1 hb-tertiary-navigation tenant-profile-sidebar-wrapper"
                :width="showAside?'250px':'0px'"
                :bottom="$vuetify.breakpoint.xs"
                :app="$vuetify.breakpoint.xs"
                flat
            >
                <v-list dense style="width:100%;">
                    <v-list-item-group v-model="selection" mandatory>
                        <v-list-item class="pl-5">
                            Lead Questionnaire
                        </v-list-item>
                        <v-list-item class="pl-5">
                            The Script  
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LeadScripts from "./LeadScripts.vue";
import LeadQuestionary from "./LeadQuestionary.vue";
import { notificationMixin } from "../../../mixins/notificationMixin";
import draggable from "vuedraggable";
import api from '../../../assets/api';
import ApwSetting from "./ApwSetting.vue";
import ReservationSettings from "./ReservationSettings.vue"
export default {
  name: "leadManagement",
  mixins: [notificationMixin],
  data() {
    return {
      content: "",
      setupModal: "",
      customQuestionnaireModel: [],
      property_id: "",
      disableBtn: true,
      showAside: true,
      questionnaires: [
        { question: 'Preferred storage location? *', isDefault: true },
        { question: 'Preferred Features and Amenities?', isDefault: true },
        { question: 'Space Details?', isDefault: true },
        { question: 'When do you need storage?', isDefault: true },
        { question: 'How long will you need storage?', isDefault: true }
      ],
      customQuestionnaires: [],
      disableAddQuestion: false,
      selection: null,
    };
  },
  components: {
    LeadScripts,
    LeadQuestionary,
    draggable,
    ApwSetting,
    ReservationSettings,
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isPropertyLevel() {
      return this.level === 'Property' ? true : false;
    },

  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty'
    }),
    addQuestion() {
      // Add a new question to the questionnaires array
      this.customQuestionnaires.push({ question: { question: 'Question 1', answers: [], isDefault: false, required: false, enabled: true }, sort_order: this.customQuestionnaires.length + 1 });
      // You can customize the default question as needed
      this.disableAddQuestion = true;
    },
    async saveNewOrder() {
      let data = this.customQuestionnaires;
      let r = await api.put(this, api.LEADS + 'questionnaire/sort-order', data);
      await this.getAllQuestions();
    },
    async getAllQuestions() {
      this.disableAddQuestion = false;
      let r = await api.get(this, api.LEADS + 'questionnaire');
      this.customQuestionnaires = r;
    },
    async setNewOrderedQuestion() {
      await this.getAllQuestions();
      await this.saveNewOrder();
      await this.getAllQuestions();
    }
  },
  props: {
    level: {
      type: String,
      default: "",
    },
    facilityID: {
      type: String,
      default: "",
    },
    isOPS: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    property_id() {
      if (!this.property_id) {
        this.disableBtn = true;
        this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id));
        this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id));
      } else {
        this.disableBtn = false;
      }
    },
    level() {
        if(this.level == 'Property'){
            this.property_id = this.defaultProperty ? this.defaultProperty.id : null
        }
    }
  },
  mounted() {
    this.getAllQuestions();
  },
  async created() {
    // this.fetchData();
  },
  beforeDestroy() { },
};
</script>

<style scoped>
.settings-lead {
  display: flex;
}

.editor-style {
  height: 300px;
}

.editor-style>>>.tiptap {
  height: 250px;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active+.v-expansion-panel {
  margin-top: 0px;
}

.sub-header-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.draggable-dropdown {
  width: -webkit-fill-available;
  margin-right: 52px;
}

.draggable-dropdown-2 {
  width: -webkit-fill-available;
  margin-right: 80px;
}

.lead-management-setting-corporate-tab {
  width: 90%;
  height: 100vh;
}

.lead-management-setting-property-tab {
  width: 100vw;
}

.questionnaire-container {
  padding-bottom: 10px;
}
</style>
